export const NOT_FOUND = 'NOT_FOUND';
export const FORBIDDEN = 'FORBIDDEN';
export const ERROR = 'ERROR';
export const SUCCESS = 'SUCCESS';
export const UNDEFINED = 'NA';

export const BLOCK_UNIT_SUCCESS = 'BLOCK_UNIT_SUCCESS';
export const BLOCK_UNIT_FAIL = 'BLOCK_UNIT_FAIL';

export const BOOK_UNIT_SUCCESS = 'BOOK_UNIT_SUCCESS';
export const BOOK_UNIT_FAIL = 'BOOK_UNIT_FAIL';

export const BOOKED = 'BOOKED';
export const BLOCKED = 'BLOCKED';

export enum ProjectStatus {
  ONGOING = 'ONGOING',
  HOLD = 'HOLD',
  ONBOARDING = 'ONBOARDING',
  RENEWAL = 'RENEWAL',
  EXPIRED = 'EXPIRED',
  LIVE = 'LIVE',
  MAINTENANCE = 'MAINTENANCE',
}

export enum UnitStatus {
  AVAILABLE = 'AVAILABLE',
  UNAVAILABLE = 'UNAVAILABLE',
  RESERVED = 'RESERVED',
  BLOCKED = 'BLOCKED',
  BOOKED = 'BOOKED',
  PURCHASED = 'PURCHASED',
  POSSESSION = 'POSSESSION',
}

type UnitStatusesWithIndex = {
  [key in UnitStatus]: number;
};

export const UNIT_STATUSES_INDEX: UnitStatusesWithIndex = {
  [UnitStatus.UNAVAILABLE]: 0,
  [UnitStatus.AVAILABLE]: 1,
  [UnitStatus.RESERVED]: 2,
  [UnitStatus.BLOCKED]: 3,
  [UnitStatus.BOOKED]: 4,
  [UnitStatus.PURCHASED]: 5,
  [UnitStatus.POSSESSION]: 6,
};
export enum ACCESS {
  READ = 'READ',
  WRITE = 'WRITE',
}

export enum LEAD_PROJECT_STATUS {
  ASSIGN = 'ASSIGN',
  UNASSIGN = 'UNASSIGN',
  SCHEDULED_SITE_VISIT = 'SCHEDULED_SITE_VISIT',
  SHORTLISTED = 'SHORTLISTED',
  BOOKED = 'BOOKED',
}

export enum CP_LEAD_STATUS {
  INTEREST = 'INTEREST',
  ASSIGN = 'ASSIGN',
  UNASSIGN = 'UNASSIGN',
  DECLINE = 'DECLINE',
  SITE_VISIT = 'SITE_VISIT',
  JUST_ADDED = 'JUST_ADDED',
  SHORTLISTING = 'SHORTLISTING',
  SCHEDULED_SITE_VISIT = 'SCHEDULED_SITE_VISIT',
  BOOKED = 'BOOKED',
  REGISTERED = 'REGISTERED', // JUST_ADDED + SHORTLISTING + SCHEDULED_SITE_VISIT
}

export enum EOI_STATUS {
  DRAFT = 'DRAFT', // EOI is created but no valid payment attached to the EOI
  INITIATED = 'INITIATED', // EOI is create and valid payment is attached to the EOI
  ACTIVE = 'ACTIVE', // Sum of valid payment attached to the EOI is greater than minimum EOI required amount
  ATTACHED = 'ATTACHED',
  BLOCKED = 'BLOCKED', // There exists exactly one unit with BLOCKED status that is attached to the EOI
  // Unit is BLOCKED after token amount is paid by the customer/lead
  BOOKED = 'BOOKED', // There exists exactly one unit with BOOKED status that is attached to the EOI
  // Unit is BOOKED after booking amount is paid by the customer/lead
  CANCELLED = 'CANCELLED', // EOI is canceled
}

export enum UnitAvailableStatus {
  AVAILABLE = 'Available',
  UNAVAILABLE = 'Unavailable',
}

export enum SITE_VISIT_FEEDBACK_LEAD_STATUS {
  HOT = 'HOT',
  WARM = 'WARM',
  COLD = 'COLD',
  LOST = 'LOST',
}

export enum FOLLOWUP_STATUS {
  OPEN = 'OPEN',
  CLOSED = 'CLOSED',
}
export enum FOLLOWUP_TYPE {
  SITE_VISIT = 'SITE_VISIT',
  PHONE_CALL = 'PHONE_CALL',
  DOCUMENTATION = 'DOCUMENTATION',
  VSG_TOUR = 'VSG_TOUR',
  NO_FOLLOW_UP = 'NO_FOLLOW_UP',
}

export enum SESSION_TYPES {
  SITE_VISIT = 'SITE_VISIT',
  CALL = 'CALL',
  VSG_TOUR = 'VSG_TOUR',
  NONE = 'NONE',
}
