import { AnimatePresence } from 'framer-motion';
import { lazy, Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import LoadingPage from '@/admin/components/common/LoadingPage';
import { getRelataAppType } from '@/admin/components/pages/ExecutiveDashboard/utils';
import ToastContainer from '@/booking/components/common/toast/ToastContainer';
import { URLS } from '@/constants/urls';
import useCheckInternetSpeed from '@/cp/hooks/useCheckInternetSpeed';

const AdminLogin = lazy(() => import('@/admin/components/pages/AdminLogin'));
const AdminLayer = lazy(
  () => import('@/admin/components/layoutBluePrints/AdminLayer')
);
const FormRoutes = lazy(() => import('@/admin/routers/FormRoutes'));
const ScannerRoutes = lazy(() => import('@/admin/routers/ScannerRoutes'));
const CommonAdminComponents = lazy(
  () => import('@/booking/components/CommonAdminComponents')
);
const CpContentLayer = lazy(
  () => import('@/cp/layoutBluePrints/CpContentLayer/index')
);
const ContentLayer = lazy(
  () => import('@/booking/layoutBluePrints/ContentLayer')
);
const FileViewer = lazy(() => import('@/cp/components/pages/FileViewer'));
const TeleCallerAddLead = lazy(
  () => import('@/admin/components/pages/TelecallerAddLead')
);
const LqrCode = lazy(() => import('@/admin/components/pages/LqrCode'));
const MitlyCode = lazy(() => import('@/admin/components/pages/MitlyCode'));

const Routes = () => {
  useCheckInternetSpeed();

  return (
    <>
      <AnimatePresence>
        <Suspense
          fallback={
            <LoadingPage
              errorMessage='Please wait while we load the experience for you...'
              pageType='loading'
            />
          }>
          <Switch>
            <Route path='/login' component={AdminLogin} />

            {getRelataAppType() !== 'RELATA-SV' && (
              <Route path='/admin' component={AdminLayer} />
            )}

            <Route path='/forms' component={FormRoutes} />

            <Route path='/scanners' component={ScannerRoutes} />

            <Route
              path='/common-admin-components'
              component={CommonAdminComponents}
            />

            {/* Relata CP - Channel Partner(Broker) */}
            <Route path={URLS.CP_BASE}>
              <CpContentLayer />
            </Route>

            {/* TODO: Add inside BookingRoutes */}
            <Route path={[URLS.DASHBOARD_BASE, URLS.BOOKING_BASE]}>
              <ContentLayer />
            </Route>

            {/*   To view the collateral share file  */}
            <Route path='/viewer' component={FileViewer} />
            <Route path={'/telecaller-addLead'} component={TeleCallerAddLead} />
            <Route path='/lqr/:pin'>
              <LqrCode />
            </Route>
            <Route path='/mtly/:mitlyCode'>
              <MitlyCode />
            </Route>

            {/* Unmatched url redirect to admin dashboard or login page base on if user has already logged in*/}
            {getRelataAppType() === 'RELATA-SV' ? (
              <Redirect to={URLS.SITE_VISIT_FORM} />
            ) : (
              <Redirect to={`/cp/invalid/invalid-application-page`} />
            )}
          </Switch>
        </Suspense>
      </AnimatePresence>
      <ToastContainer />
    </>
  );
};

export default Routes;
