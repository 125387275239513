import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { unitCartAPISlice } from '@/api/unitCart';
import type { IScheme, IUnit } from '@/interfaces';
import type { IUnitCart } from '@/cp/interfaces';

interface IState {
  unit?: IUnit;
  unitCart?: IUnitCart;
  isFetchingUnitCart: boolean;
  selectedScheme?: IScheme;
}

const initialState: IState = {
  isFetchingUnitCart: false,
};

const sliceName = 'seletedUnitSlice';

interface ISeletedUnitSlice {
  [sliceName]: IState;
}

export const seletedUnitSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    setSelectedUnit: (state, action: PayloadAction<IUnit | undefined>) => {
      // Reset unitCart on user update or unit update
      const isUnitChanged =
        !action.payload || state.unit?.id !== action.payload.id;
      if (isUnitChanged) {
        state.unitCart = {} as IUnitCart;
      }
      state.unit = action.payload;
    },
    setUnitCart: (state, action: PayloadAction<IUnitCart | undefined>) => {
      state.unitCart = action.payload;
    },
    resetUnitCartExpiryOn: (state) => {
      if (state.unitCart) {
        state.unitCart.expiredOn = undefined;
      }
    },
    // setSelectedSchemeId: (state, action) => {
    //   state.unitCart['selectedSchemeId'] = action.payload;
    // },
    setSelectedScheme: (state, action: PayloadAction<IScheme>) => {
      state.selectedScheme = action.payload;
    },
  },
  extraReducers: (builder) => {
    // Update unitCart in store
    builder.addMatcher(
      unitCartAPISlice.endpoints.getUnitCart.matchFulfilled,
      (state, { payload }) => {
        state.unitCart = payload || {};
      }
    );

    // handle isFetchingUnitCart
    builder.addMatcher(
      unitCartAPISlice.endpoints.getUnitCart.matchFulfilled,
      (state, { payload }) => {
        state.isFetchingUnitCart = false;
      }
    );
    builder.addMatcher(
      unitCartAPISlice.endpoints.getUnitCart.matchRejected,
      (state, { payload }) => {
        state.isFetchingUnitCart = false;
      }
    );
    builder.addMatcher(
      unitCartAPISlice.endpoints.getUnitCart.matchPending,
      (state, { payload }) => {
        state.isFetchingUnitCart = true;
      }
    );
  },
});

export const {
  setSelectedUnit,

  setUnitCart,
  resetUnitCartExpiryOn,
  // setSelectedSchemeId,
  setSelectedScheme,
} = seletedUnitSlice.actions;

export const getSelectedUnit = (state: ISeletedUnitSlice) =>
  state.seletedUnitSlice.unit;
// export const getEmbassyUnitId = (state: ISeletedUnitSlice) =>
//   state.seletedUnitSlice.unit.embassy_unit_id;

export const getUnitCart = (state: ISeletedUnitSlice) =>
  state.seletedUnitSlice.unitCart;
export const getIsFetchingUnitCart = (state: ISeletedUnitSlice) =>
  state.seletedUnitSlice.isFetchingUnitCart;
// export const getSelectedSchemeId = (state) => {
//   const costSheet = getCostSheet(state);
//   const unitCart = getUnitCart(state);

//   const selectedSchemeSfdcId =
//     unitCart?.scheme_sfdc_id || costSheet?.scheme_details[0].scheme_sfdc_id;
//   return selectedSchemeSfdcId;
// };
export const getSelectedScheme = (state: ISeletedUnitSlice) =>
  state.seletedUnitSlice.selectedScheme;
export const getCostSheet = (state: ISeletedUnitSlice) => {
  const selectedUnit = state.seletedUnitSlice.unit;
  const costSheet = selectedUnit?.costSheets
    ? selectedUnit?.costSheets[0]
    : undefined;
  return costSheet;
};
