export enum PRICE_UNIT {
  LAKH = 'Lakh',
  CRORE = 'Cr',
}

export enum MEASURE_UNIT {
  SQMT = 'SQMT',
  SQFT = 'SQFT',
  METER = 'METER',
}

export enum COSTSHEET_SECTION {
  SUMMARY = 'SUMMARY',
  BREAKUP = 'BREAKUP',
  ADDITIONAL = 'ADDITIONAL',
}

export enum COSTSHEET_ITEM_TYPE {
  BASE_SELLING_PRICE = 'BASE_SELLING_PRICE',
  PLC = 'PLC',
  EDC = 'EDC',
  CLUB_CHARGE = 'CLUB_CHARGE',
  IFMS = 'IFMS',
  GST = 'GST',
  TDS = 'TDS',
}

export enum MILESTONE_KEY {
  NAME = 'name',
  PERCENTAGE = 'percentage',
  PROPERTY_VALUE = 'property_value',
  GST = 'gst',
  NET_TAXABLE_AMOUNT = 'net_taxable_amount',
  PAID_AMOUNT = 'paid',
  STATUS = 'status',
}

//TODO: handle this at admin-portal + backend
export interface IEoiPaymentType {
  PLATINUM: {
    ROOTS: number;
    WAVE: number;
    AIR: number;
    SKY: number;
  };
  SILVER: {
    ROOTS: number;
    WAVE: number;
    AIR: number;
    SKY: number;
  };
}

export const EOI_PAYMENT = {
  PLATINUM: {
    ROOTS: 300000,
    WAVE: 500000,
    AIR: 1000000,
    SKY: 2000000,
    '1BHK': 50000,
    '2BHK': 100000,
    '3BHK': 1000000,
  },
  SILVER: {
    ROOTS: 50000,
    WAVE: 50000,
    AIR: 50000,
    SKY: 50000,
    '1BHK': 50000,
    '2BHK': 100000,
    '3BHK': 1000000,
  },
  GOLD: {
    ROOTS: 50000,
    WAVE: 50000,
    AIR: 50000,
    SKY: 50000,
    '1BHK': 50000,
    '2BHK': 100000,
    '3BHK': 1000000,
  },
  BRONZE: {
    ROOTS: 50000,
    WAVE: 50000,
    AIR: 50000,
    SKY: 50000,
    '1BHK': 50000,
    '2BHK': 100000,
    '3BHK': 1000000,
  },
};

export enum APPLICANT_TYPE {
  PRIMARY = 'Primary',
  SECONDARY = 'Secondary',
}

export enum MARITAL_STATUS {
  SINGLE = 'Single',
  MARRIED = 'Married',
  DIVORCED = 'Divorced',
  WIDOWED = 'Widowed',
}

export enum PERSON_TITLE {
  MR = 'Mr.',
  MRS = 'Mrs.',
  MS = 'Ms.',
}

export enum DISCOUNT_MODE {
  ABSOLUTE = 'ABSOLUTE',
  PERCENTAGE = 'PERCENTAGE',
}

export enum PAYMENT_ORDER_STATUS {
  INITIATED = 'INITIATED',
  PROCESSING = 'PROCESSING',
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED',
}

export const PAYMENT_MODES = {
  CASH_CHALLAN: {
    value: 'CASH_CHALLAN',
    displayText: 'Cash challan',
  },
  CHEQUE: { value: 'CHEQUE', displayText: 'Cheque' },
  NETBANKING: { value: 'NETBANKING', displayText: 'Netbanking' },
  RAZORPAY: { value: 'RAZORPAY', displayText: 'Razorpay' },
  UPI: { value: 'UPI', displayText: 'UPI' },
  CREDIT_CARD: { value: 'CREDIT_CARD', displayText: 'Credit Card' },
  DEBIT_CARD: { value: 'DEBIT_CARD', displayText: 'Debit Card' },
};

export enum GENDER {
  MALE = 'Male',
  FEMALE = 'Female',
  OTHER = 'Other',
}
