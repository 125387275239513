import { useMediaQuery } from 'usehooks-ts';

import { Responsive } from '@/atoms/common/layout/layout';

export const useResponsive = () => {
  const xs = useMediaQuery(`(max-width: ${breakpoints['sm'] - 1}px)`);
  const sm = useMediaQuery(`(max-width: ${breakpoints['md'] - 1}px)`);
  const md = useMediaQuery(`(max-width: ${breakpoints['lg'] - 1}px)`);
  const lg = useMediaQuery(`(max-width: ${breakpoints['xl'] - 1}px)`);
  const xl = useMediaQuery(`(min-width: ${breakpoints['xl']}px)`);

  return {
    xs,
    sm,
    md,
    lg,
    xl,

    mobile: xs,
    tablet: sm,
    desktop: md,

    responsiveConstant: xs
      ? 'initial'
      : sm
      ? 'sm'
      : md
      ? 'md'
      : lg
      ? 'lg'
      : 'xl',
  } as const;
};

export const breakpoints: Record<keyof Responsive<unknown>, number> = {
  initial: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
};
