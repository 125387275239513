import {
  IAllocationDetails,
  IGetAllocationCountReq,
  IGetAllocationReq,
} from '@/admin/components/pages/ProjectAllocations/AllocationsList';
import { IBookingPaymentRow } from '@/admin/components/pages/ProjectBookingPayments/PaymentList';
import { IDeleteUserFromProjectRequest } from '@/admin/components/pages/ProjectExecutives/RemoveProjectExecutiveCell';
import { IDemoRoom } from '@/admin/components/pages/SuperAdminDashboard/SuperAdminProjectList/modals/AddDemoRoomModal';
import {
  IProjectEvent,
  IProjectEventReq,
} from '@/admin/components/pages/SuperAdminDashboard/SuperAdminProjectList/modals/ProjectEventsModal';
import type { IAddEoi, IEOI } from '@/admin/interfaces';
import { adminAPISlice } from '@/admin/services/index';
import { PAYMENT_ORDER_STATUS } from '@/booking/constants';
import type {
  IProject,
  IProjectExecutive,
  IResponseBase,
  TObjectId,
} from '@/interfaces';
import type {
  ITransformCountResponse,
  ITransformProjectResponse,
  ITransformProjectsResponse,
} from '@/interfaces/services';

export const projectsAPISlice = adminAPISlice.injectEndpoints({
  endpoints: (build) => ({
    getProjects: build.mutation({
      query: ({
        pageNumber,
        pageCount,
        searchName = '',
      }: {
        pageNumber: number;
        pageCount: number;
        searchField?: string;
        searchName?: string;
        startDate?: string;
        endDate?: string;
      }) => ({
        url: `api/projects?searchName=${searchName}&pageCount=${pageCount}&pageNumber=${pageNumber}`,
        method: 'GET',
      }),
    }),
    getProjectsCount: build.query({
      query: () => 'api/projects/count',
      transformResponse: (response: ITransformCountResponse) => {
        return response.data;
      },
    }),
    getProjectsByProjectId: build.query({
      query: (projectId: string) => `api/projects/${projectId}`,
      transformResponse: (response: ITransformProjectResponse) => {
        return response.data;
      },
    }),
    getProjectsByUserId: build.query<IProject[], string | undefined>({
      query: (userId: string) => `api/projects/users/${userId}`,
      transformResponse: (response: ITransformProjectsResponse) => {
        return response.data;
      },
    }),
    getProjectSelectionByUserId: build.query<IProject[], string | undefined>({
      query: (userId: string) => `api/projects/users/${userId}/selection`,
      transformResponse: (response: ITransformProjectsResponse) => {
        return response.data;
      },
    }),
    addProject: build.mutation({
      query: (project: IProject) => ({
        url: 'api/projects/',
        method: 'POST',
        body: project,
      }),

      invalidatesTags: [{ type: 'Projects', id: 'LIST' }],

      transformResponse: (response: ITransformProjectResponse) => {
        return response.data;
      },
    }),
    updateProject: build.mutation({
      query: ({ projectId, ...fieldsToUpdate }) => ({
        url: `api/projects/${projectId}`,
        method: 'PUT',
        body: fieldsToUpdate,
      }),
      // invalidatesTags: [{ type: 'Projects', id: 'LIST' }]
    }),
    updateProjectDetails: build.mutation({
      query: ({ projectId, ...fieldsToUpdate }) => ({
        url: `api/projects/${projectId}/details`,
        method: 'PUT',
        body: fieldsToUpdate,
      }),
    }),

    addDemoRoom: build.mutation({
      query: ({ projectId, demoRoom }) => ({
        url: `api/projects/${projectId}/demo-rooms`,
        method: 'POST',
        body: demoRoom,
      }),
      // invalidatesTags: (result, error, { id }) => [{ type: 'Projects', id }]
    }),
    getDemoRoom: build.query<IDemoRoom, IDemoRoomQuery>({
      query: ({ projectId, executiveId }) =>
        `api/projects/${projectId}/executives/${executiveId}/demo-rooms`,
      transformResponse: (response: IDemoRoomQueryResponse) => {
        return response.data;
      },
    }),
    updateDemoRoom: build.mutation({
      query: ({ projectId, demoRoomId, demoRoom }) => ({
        url: `api/projects/${projectId}/demo-rooms/${demoRoomId}`,
        method: 'PUT',
        body: demoRoom,
      }),
      // invalidatesTags: (result, error, { id }) => [{ type: 'Projects', id }]
    }),
    deleteDemoRoom: build.mutation({
      query: ({ projectId, demoRoomId }) => ({
        url: `api/projects/${projectId}/demo-rooms/${demoRoomId}`,
        method: 'DELETE',
      }),

      // invalidatesTags: (result, error, { id }) => [{ type: 'Projects', id }]
    }),

    addProjectExecutive: build.mutation({
      query: ({
        projectId,
        executiveId,
        vrUrl,
        teamLeadId,
      }: IProjectExecutive) => ({
        url: `api/projects/${projectId}/executivesWithDemoRoom`,
        method: 'POST',
        body: { executiveId, vrUrl, teamLeadId },
      }),
      // invalidatesTags: (result, error, { id }) => [{ type: 'Projects', id }]
    }),

    updateProjectExecutive: build.mutation({
      query: ({
        projectId,
        executiveId,
        vrUrl,
        teamLeadId,
      }: IProjectExecutive) => ({
        url: `api/projects/${projectId}/executivesWithDemoRoom`,
        method: 'PUT',
        body: { executiveId, vrUrl, teamLeadId },
      }),
    }),

    deleteExecutiveFromProject: build.mutation<
      { data: IProject },
      IDeleteUserFromProjectRequest
    >({
      query: ({ projectId, userId }) => ({
        url: `api/projects/${projectId}/executivesWithDemoRoom/${userId}`,
        method: 'DELETE',
      }),
      invalidatesTags: (_, __, { projectId }) => [
        { type: 'Projects-Users', id: projectId },
      ],
      // invalidatesTags: (result, error, { id }) => [{ type: 'Projects', id }]
    }),
    deleteGreFromProject: build.mutation<
      { data: IProject },
      IDeleteUserFromProjectRequest
    >({
      query: ({ projectId, userId }) => ({
        url: `api/projects/${projectId}/gre/${userId}`,
        method: 'DELETE',
      }),
      invalidatesTags: (_, __, { projectId }) => [
        { type: 'Projects-Users', id: projectId },
      ],
      // invalidatesTags: (result, error, { id }) => [{ type: 'Projects', id }]
    }),
    deletePreSaleFromProject: build.mutation<
      { data: IProject },
      IDeleteUserFromProjectRequest
    >({
      query: ({ projectId, userId }) => ({
        url: `api/projects/${projectId}/executivesWithDemoRoom/${userId}`,
        method: 'DELETE',
      }),
      invalidatesTags: (_, __, { projectId }) => [
        { type: 'Projects-Users', id: projectId },
      ],
      // invalidatesTags: (result, error, { id }) => [{ type: 'Projects', id }]
    }),
    deleteFinanceExecutiveFromProject: build.mutation<
      { data: IProject },
      IDeleteUserFromProjectRequest
    >({
      query: ({ projectId, userId }) => ({
        url: `api/projects/${projectId}/executivesWithDemoRoom/${userId}`,
        method: 'DELETE',
      }),
      invalidatesTags: (_, __, { projectId }) => [
        { type: 'Projects-Users', id: projectId },
      ],
      // invalidatesTags: (result, error, { id }) => [{ type: 'Projects', id }]
    }),

    getProjectEventsByProjectId: build.query<IProjectEvent[], string>({
      query: (projectId: string) => `api/projects/events/projects/${projectId}`,
      transformResponse: (response: ITransformProjectEventsResponse) => {
        return response.data;
      },
    }),
    addProjectEvent: build.mutation({
      query: (data: IProjectEventReq) => ({
        url: 'api/projects/events/',
        method: 'POST',
        body: data,
      }),
    }),
    updateProjectEvent: build.mutation({
      query: ({ projectEventId, ...fieldsToUpdate }) => ({
        url: `api/projects/events/${projectEventId}`,
        method: 'PUT',
        body: fieldsToUpdate,
      }),
    }),

    addProjectEoi: build.mutation({
      query: (data: IAddEoi) => ({
        url: 'api/projects/eoi/add',
        method: 'POST',
        body: data,
      }),
    }),

    updateProjectEoi: build.mutation({
      query: (data: IEOI | { lead: TObjectId; executive: TObjectId }) => ({
        url: 'api/projects/eoi/update',
        method: 'PUT',
        body: data,
      }),
    }),

    updateEoiStatusByEoiId: build.mutation({
      query: ({ eoiId, isBookingEnabled, requestPayload }) => ({
        url: `api/projects/eoi/${eoiId}/${isBookingEnabled}`,
        method: 'PUT',
        body: requestPayload,
      }),
    }),

    // getProjectEoisByProjectId: build.query<IEOI[], any>({
    //   query: (projectId: TObjectId) => `api/projects/eoi/project/${projectId}`,
    //   transformResponse: (response: {
    //     data: IEOI[];
    //     code: string;
    //     error: Object;
    //     message: string;
    //   }) => {
    //     return response.data;
    //   },
    // }),

    getProjectEoisCount: build.query<
      number,
      {
        projectId: TObjectId;
        executive: TObjectId;
      }
    >({
      query: ({
        projectId,
        executive,
      }: {
        projectId: TObjectId;
        executive: TObjectId;
      }) =>
        `api/projects/eoi/project/${projectId}/count?executive=${executive}`,
      transformResponse: (response: ITransformProjectCountResponse) => {
        return response.data.totalCount;
      },
    }),

    getProjectActiveEoisCount: build.query<
      number,
      {
        projectId: TObjectId;
        executive: TObjectId;
        status?: string | undefined;
      }
    >({
      query: ({
        projectId,
        executive,
        status = 'ACTIVE',
      }: {
        projectId: TObjectId;
        executive: TObjectId;
        status: string;
      }) =>
        `api/projects/eoi/project/${projectId}/count?executive=${executive}&status=${status}`,
      transformResponse: (response: ITransformProjectCountResponse) => {
        return response.data.totalCount;
      },
    }),

    getProjectDisabledEoisCount: build.query<
      number,
      {
        projectId: TObjectId;
        executive: TObjectId;
        isBookingEnabled?: string;
      }
    >({
      query: ({
        projectId,
        executive,
        isBookingEnabled = 'false',
      }: {
        projectId: TObjectId;
        executive: TObjectId;
        isBookingEnabled: string;
      }) =>
        `api/projects/eoi/project/${projectId}/count?executive=${executive}&isBookingEnabled=${isBookingEnabled}`,
      transformResponse: (response: ITransformProjectCountResponse) => {
        return response.data.totalCount;
      },
    }),

    getProjectEnabledEoisCount: build.query<
      number,
      {
        projectId: TObjectId;
        executive: TObjectId;
        isBookingEnabled?: string;
      }
    >({
      query: ({
        projectId,
        executive,
        isBookingEnabled = 'true',
      }: {
        projectId: TObjectId;
        executive: TObjectId;
        isBookingEnabled: string;
      }) =>
        `api/projects/eoi/project/${projectId}/count?executive=${executive}&isBookingEnabled=${isBookingEnabled}`,
      transformResponse: (response: ITransformProjectCountResponse) => {
        return response.data.totalCount;
      },
    }),

    getProjectEoisByProjectId: build.mutation({
      query: ({
        projectId,
        pageNumber,
        pageCount,
        status,
        executive,
        isBookingEnabled = '',
        searchField = '',
        searchName = '',
        startDate = '',
        endDate = '',
      }: {
        projectId?: TObjectId;
        pageNumber: number;
        pageCount: number;
        status: string;
        executive?: TObjectId;
        isBookingEnabled?: string;
        searchField?: string;
        searchName?: string;
        startDate?: string;
        endDate?: string;
      }) => ({
        url: `api/projects/eoi/project/${projectId}?executive=${executive}&status=${status}&searchField=${searchField}&searchName=${searchName}&pageCount=${pageCount}&pageNumber=${pageNumber}&startDate=${startDate}&endDate=${endDate}&isBookingEnabled=${isBookingEnabled}`,
        method: 'GET',
      }),
    }),

    getProjectEoiPaymentsByProjectId: build.mutation({
      query: ({
        projectId,
        pageNumber,
        pageCount,
        statuses,
        searchField = '',
        searchName = '',
        startDate = '',
        endDate = '',
      }: {
        projectId?: TObjectId;
        pageNumber: number;
        pageCount: number;
        statuses: PAYMENT_ORDER_STATUS[];
        searchField?: string;
        searchName?: string;
        startDate?: string;
        endDate?: string;
      }) => ({
        url: `api/payment/eoiPaymentOrders/${projectId}?${statuses
          .map((status) => `statuses=${status}`)
          .join(
            '&'
          )}&pageCount=${pageCount}&pageNumber=${pageNumber}&${searchField}=${searchName}&startDate=${startDate}&endDate=${endDate}`,

        method: 'GET',
      }),
    }),

    getProjectEoiPaymentsCountByProjectId: build.mutation({
      query: ({
        projectId,
        statuses,
        searchField = '',
        searchName = '',
        startDate = '',
        endDate = '',
      }: {
        projectId?: TObjectId;
        statuses: PAYMENT_ORDER_STATUS[];
        searchField?: string;
        searchName?: string;
        startDate?: string;
        endDate?: string;
      }) => ({
        url: `api/payment/eoiPaymentOrders/count/${projectId}?${statuses
          .map((status) => `statuses=${status}`)
          .join(
            '&'
          )}&${searchField}=${searchName}&startDate=${startDate}&endDate=${endDate}`,

        method: 'GET',
      }),
    }),

    getProjectBookingPaymentsByProjectId: build.mutation({
      query: ({
        projectId,
        pageNumber,
        pageCount,
        statuses,
        searchField = '',
        searchName = '',
        startDate = '',
        endDate = '',
      }: {
        projectId?: TObjectId;
        pageNumber: number;
        pageCount: number;
        statuses: PAYMENT_ORDER_STATUS[];
        searchField?: string;
        searchName?: string;
        startDate?: string;
        endDate?: string;
      }) => ({
        url: `api/payment/unitCartPaymentOrders/${projectId}`,
        method: 'GET',
        params: {
          pageCount,
          pageNumber,
          statuses,
          [searchField]: searchName,
          startDate,
          endDate,
        },
      }),
      transformResponse: (response: ITransformPaymentOrdersResponse) => {
        return response.data.paymentOrders;
      },
    }),

    getProjectBookingPaymentsCountByProjectId: build.mutation({
      query: ({
        projectId,
        statuses,
        searchName = '',
        searchField = '',
        startDate = '',
        endDate = '',
      }: {
        projectId?: TObjectId;
        statuses: PAYMENT_ORDER_STATUS[];
        searchField?: string;
        searchName?: string;
        startDate?: string;
        endDate?: string;
      }) => ({
        url: `api/payment/unitCartPaymentOrders/count/${projectId}`,
        method: 'GET',
        params: {
          statuses,
          [searchField]: searchName,
          startDate,
          endDate,
        },
      }),
      transformResponse: (response: ITransformProjectCountResponse) => {
        return response.data.totalCount;
      },
    }),

    getProjectAllocationsByProjectId: build.mutation({
      query: (getAllocationReq: IGetAllocationReq) => ({
        url: `api/units/unitCart/allocations`,
        method: 'GET',
        params: {
          ...getAllocationReq,
          [getAllocationReq.searchField]: getAllocationReq.searchName,
        },
      }),
      transformResponse: (response: ITransformUnitCartsResponse) => {
        return response.data.unitCarts;
      },
    }),

    getProjectAllocationsCountByProjectId: build.mutation({
      query: (allocationCountReq: IGetAllocationCountReq) => ({
        url: `api/units/unitCart/allocations/count`,
        method: 'GET',
        params: {
          ...allocationCountReq,
          [allocationCountReq.searchField]: allocationCountReq.searchName,
        },
      }),
      transformResponse: (response: ITransformProjectCountResponse) => {
        return response.data.totalCount;
      },
    }),

    getAllocationByUnitCartId: build.mutation({
      query: (unitCartId: TObjectId) => ({
        url: `api/units/unitCart/allocation/${unitCartId}`,
        method: 'GET',
      }),
      transformResponse: (response: ITransformUnitCartResponse) => {
        return response.data;
      },
    }),

    updateProjectUnitStatus: build.mutation({
      query: ({ unitId, visibilityStatus, requestPayload }) => ({
        url: `api/units/${unitId}/status/${visibilityStatus}`,
        method: 'PUT',
        body: requestPayload,
      }),
    }),
  }),

  overrideExisting: false,
});

interface ITransformProjectEventsResponse extends IResponseBase {
  data: IProjectEvent[];
}

interface ITransformProjectCountResponse extends IResponseBase {
  data: {
    totalCount: number;
  };
}

interface ITransformPaymentOrdersResponse extends IResponseBase {
  data: { paymentOrders: IBookingPaymentRow[] };
}

interface ITransformUnitCartsResponse extends IResponseBase {
  data: { unitCarts: IAllocationDetails[] };
}

interface ITransformUnitCartResponse extends IResponseBase {
  data: IAllocationDetails;
}

export const {
  useGetProjectsMutation,
  useGetProjectsCountQuery,
  useGetProjectsByProjectIdQuery,
  useGetProjectsByUserIdQuery,
  useGetProjectSelectionByUserIdQuery,
  useAddProjectMutation,
  useUpdateProjectMutation,
  useUpdateProjectDetailsMutation,

  useAddDemoRoomMutation,
  useGetDemoRoomQuery,
  useUpdateDemoRoomMutation,
  useDeleteDemoRoomMutation,

  useAddProjectExecutiveMutation,
  useUpdateProjectExecutiveMutation,
  useDeleteExecutiveFromProjectMutation,
  useDeleteGreFromProjectMutation,
  useDeletePreSaleFromProjectMutation,
  useDeleteFinanceExecutiveFromProjectMutation,

  useGetProjectEventsByProjectIdQuery,
  useAddProjectEventMutation,
  useUpdateProjectEventMutation,

  useAddProjectEoiMutation,
  useUpdateEoiStatusByEoiIdMutation,

  useGetProjectEoisCountQuery,
  useGetProjectActiveEoisCountQuery,
  useGetProjectDisabledEoisCountQuery,
  useGetProjectEnabledEoisCountQuery,

  useGetProjectEoisByProjectIdMutation,
  useGetProjectEoiPaymentsByProjectIdMutation,
  useGetProjectEoiPaymentsCountByProjectIdMutation,

  useGetProjectBookingPaymentsByProjectIdMutation,
  useGetProjectBookingPaymentsCountByProjectIdMutation,

  useGetProjectAllocationsByProjectIdMutation,
  useGetProjectAllocationsCountByProjectIdMutation,

  useGetAllocationByUnitCartIdMutation,

  useUpdateProjectUnitStatusMutation,
  useUpdateProjectEoiMutation,
} = projectsAPISlice;

interface IDemoRoomQuery {
  projectId: TObjectId;
  executiveId: TObjectId;
}

interface IDemoRoomQueryResponse extends IResponseBase {
  data: IDemoRoom;
}
