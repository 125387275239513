import { AnimatePresence, motion } from 'framer-motion';
import { useState } from 'react';

import { Clickable } from '@/atoms/Clickable';
import { ConditionalRendering } from '@/atoms/ConditionalRendering';
import { Flex } from '@/atoms/Flex';
import { Icon } from '@/atoms/Icon';
import { ITextProps, Text } from '@/atoms/Text/Text';

import styles from './styles.module.css';

export const Copy = (props: ICopyProps) => {
  const { children, fullWidth = false, disabled = false } = props;

  const [copied, setCopied] = useState(false);

  const copyText = () => {
    if (disabled) return;

    const { text } = children.props;
    navigator.clipboard.writeText(text);
    setCopied(true);
  };

  return (
    <Clickable onClick={copyText} fullWidth={fullWidth}>
      <Flex spacing={1} alignItems='center'>
        <div className={`${fullWidth && styles.fullWidthText}`}>{children}</div>
        <ConditionalRendering showIf={!disabled}>
          <div className={styles.copyContainer}>
            <Icon size='sm' name='content_copy' />
            <ConditionalRendering showIf={copied}>
              <AnimatePresence>
                <motion.div
                  className={styles.copyAlert}
                  onAnimationComplete={() => {
                    setCopied(false);
                  }}
                  initial={{ opacity: 0, y: 0 }}
                  animate={{ opacity: 1, y: -10 }}
                  transition={{
                    type: 'spring',
                    stiffness: 100,
                    duration: 2,
                  }}
                  exit={{ opacity: 0, y: 0 }}>
                  <Text size='xs' text='Copied' color='color-primary-97' />
                </motion.div>
              </AnimatePresence>
            </ConditionalRendering>
          </div>
        </ConditionalRendering>
      </Flex>
    </Clickable>
  );
};

interface ICopyProps {
  children: React.ReactElement<ITextProps>;
  fullWidth?: boolean;
  disabled?: boolean;
}
