import { ErrorBoundary } from 'react-error-boundary';
import { Provider } from 'react-redux';
// TODO: Setup an eslint to show errors for wrong imports
import { BrowserRouter } from 'react-router-dom';

import { ErrorFallback } from '@/components/atoms/ErrorBoundary/ErrorFallback';

import Routes from './routers/Routes';
import { store } from './store';

const App = () => {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <div className='App'>
        <Provider store={store}>
          <BrowserRouter basename=''>
            <Routes />
          </BrowserRouter>
        </Provider>
      </div>
    </ErrorBoundary>
  );
};
export default App;
