// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { baseUrl } from '@/constants/urls';

export const authAPISlice = createApi({
  reducerPath: 'authAPI',
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders(headers) {
      return headers;
    },
  }),
  endpoints: (build) => ({
    // Not used for Embassy
    // login: build.query({
    //   query: ({ email, password }) => {
    //     return `login/${email}/${password}`;
    //   },
    //   keepUnusedDataFor: 120
    // }),

    /*
      Old API get fetch user by mobile used in Embassy Backend.
      Use useGetUserByMobileMutation to get use by mobile
    */
    getUserByMobileOld: build.mutation({
      query: ({ mobile_no = '' } = {}) => {
        return {
          url: `user/mobile/${mobile_no}`,
          method: 'GET',
        };
      },
    }),

    sendOtp: build.mutation({
      query: ({ mobile_no }) => {
        return {
          url: `send_otp/${mobile_no}`,
          method: 'PUT',
        };
      },
    }),

    verifyOtp: build.mutation({
      query: ({ mobile_no = '', otp = '' } = {}) => {
        return {
          url: `verify_otp/${mobile_no}/${otp}`,
          method: 'GET',
        };
      },
    }),

    register: build.mutation({
      query: ({ ...body }) => {
        return {
          url: `register`,
          method: 'POST',
          body,
        };
      },
    }),

    updateUser: build.mutation({
      query: ({ id, ...body }) => {
        return {
          url: `user/update?id=${id}`,
          method: 'PUT',
          body,
        };
      },
    }),
  }),
});

export const {
  useLoginQuery,
  useRegisterMutation,
  useGetUserByMobileOldMutation,
  // useUpdateUserMutation,

  // Otp
  useSendOtpMutation,
  useVerifyOtpMutation,
} = authAPISlice;
