import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IUser, TObjectId } from '@/interfaces';

import { IAssignedCpPreview } from './CpAllocation';

export enum SiteVisitFormStates {
  PROJECT_SELECTION,
  OTP_VERIFICATION,
  LEAD_DETAILS,
  SOURCE_SELECTION,
  QR_CODE_SCANNER,
  CP_ALLOCATION,
  SUMMERY, // TODO: change to SUMMARY
  ADD_PARTNER_FORM,
}

const initialState: IState = {
  siteVisitFormState: SiteVisitFormStates.PROJECT_SELECTION,
  siteVisitDetails: {
    leadUserExists: false,
    isRevisit: false,
  },
  contendCpLeads: [],
  engagedPartners: [],
};

const sliceName = 'siteVisit2Slice';

export const siteVisit2Slice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    setSiteVisitFormState: (
      state,
      action: PayloadAction<SiteVisitFormStates>
    ) => {
      state.siteVisitFormState = action.payload;
    },
    setSiteVisitMobile: (state, action: PayloadAction<string>) => {
      state.siteVisitDetails.mobile = action.payload;
    },
    setSiteVisitLeadDetails: (state, action) => {
      state.siteVisitDetails = { ...state.siteVisitDetails, ...action.payload };
    },
    addCpLeadToContendCpLeads: (
      state,
      action: PayloadAction<IContendingCpLead>
    ) => {
      state.contendCpLeads.push(action.payload);
    },
    setContendCpLeads: (state, action: PayloadAction<IContendingCpLead[]>) => {
      state.contendCpLeads = action.payload;
    },
    resetContendCpLeads: (state) => {
      state.contendCpLeads = [];
    },
    setClosingManager: (state, action: PayloadAction<IClosingManager>) => {
      state.closingManager = action.payload;
    },
    unsetClosingManager: (state) => {
      delete state.closingManager;
    },
    setCpUser: (state, action: PayloadAction<IUser | undefined>) => {
      state.cpUser = action.payload;
    },
    setAssignedCpPreview: (
      state,
      action: PayloadAction<IAssignedCpPreview>
    ) => {
      state.assignedCpPreview = action.payload;
    },
    resetAssignedCpPreview: (state) => {
      state.assignedCpPreview = undefined;
    },
    setSiteVisitLqr: (
      state,
      action: PayloadAction<{ pin: string; url: string }>
    ) => {
      state.lqr = action.payload;
    },

    addPartnerToEngagedPartners: (
      state,
      action: PayloadAction<ITransformedEngagedPartner>
    ) => {
      state.engagedPartners.push(action.payload);
    },
    setEngagedPartners: (
      state,
      action: PayloadAction<ITransformedEngagedPartner[]>
    ) => {
      state.engagedPartners = action.payload;
    },

    resetSiteVisit: (state) => {
      state.closingManager = initialState.closingManager;
      state.cpUser = initialState.cpUser;
      state.contendCpLeads = initialState.contendCpLeads;
      state.siteVisitDetails = initialState.siteVisitDetails;
      state.lqr = initialState.lqr;
      state.siteVisitFormState = initialState.siteVisitFormState;
      state.engagedPartners = initialState.engagedPartners;
    },
  },
});

export const {
  setSiteVisitFormState,
  setSiteVisitMobile,
  setSiteVisitLeadDetails,

  addCpLeadToContendCpLeads,
  setContendCpLeads,
  resetContendCpLeads,

  setAssignedCpPreview,
  resetAssignedCpPreview,

  setSiteVisitLqr,

  resetSiteVisit,

  setClosingManager,
  unsetClosingManager,
  setCpUser,

  addPartnerToEngagedPartners,
  setEngagedPartners,
} = siteVisit2Slice.actions;

// TODO: what are these exports /.
export const getSiteVisitFormState = (state: ISiteVisit2Slice) =>
  state.siteVisit2Slice.siteVisitFormState;
export const getSiteVisitDetails = (state: ISiteVisit2Slice) =>
  state.siteVisit2Slice.siteVisitDetails;

export const getContendCpLeads = (state: ISiteVisit2Slice) =>
  state.siteVisit2Slice.contendCpLeads;

export const getClosingManager = (state: ISiteVisit2Slice) =>
  state.siteVisit2Slice.closingManager;

export const getAssignedCpPreview = (state: ISiteVisit2Slice) =>
  state.siteVisit2Slice.assignedCpPreview;

export const getSiteVisitLqr = (state: ISiteVisit2Slice) =>
  state.siteVisit2Slice.lqr;

export const getCpUser = (state: ISiteVisit2Slice) =>
  state.siteVisit2Slice.cpUser;

export const getEngagedPartner = (state: ISiteVisit2Slice) =>
  state.siteVisit2Slice.engagedPartners;

export interface ISiteVisitDetailsNew {
  leadUserExists: boolean;
  leadUserId?: TObjectId;
  isRevisit: boolean;
  name?: string;
  mobile?: string;
  email?: string;
  lqr?: string;

  location?: string;
  city?: string;
  occupation?: string;
  ageGroup?: string;
  ethnicity?: string;
  source?: {
    name: string;
    subSource: string;
  };
  addedCpMobile?: string;
  isSummaryPage?: boolean;
}
interface IState {
  siteVisitFormState: SiteVisitFormStates;
  siteVisitDetails: ISiteVisitDetailsNew;
  contendCpLeads: IContendingCpLead[];
  assignedCpPreview?: IAssignedCpPreview;
  closingManager?: IClosingManager;
  lqr?: {
    pin: string;
    url: string;
  };
  cpUser?: IUser;
  engagedPartners: ITransformedEngagedPartner[];
}

export interface IContendingCpLead {
  id: TObjectId;
  cpUserId: TObjectId;
}

export interface IClosingManager {
  name: string;
  id: string;
}

export interface ITransformedEngagedPartner {
  id: TObjectId;
  name: string;
  mobile: string;
  firmName?: string;
  reraNumber?: string;
  createdAt: Date;
  assignedSm?: {
    userId: TObjectId;
    name: string;
  };
  winnerCp?: boolean;
}
export interface ISiteVisit2Slice {
  [sliceName]: IState;
}
