import styles from './styles.module.css';

export const Clickable = (props: IClickableProps) => {
  const { children, onClick, fullWidth = false } = props;

  return (
    <button
      className={`${styles.clickable} ${fullWidth ? styles.fullWidth : ''}`}
      onClick={onClick}>
      {children}
    </button>
  );
};

interface IClickableProps {
  children: JSX.Element;
  onClick: () => void;
  fullWidth?: boolean;
}
