import { IDeleteUserFromCompanyRequest } from '@/admin/components/pages/AdminDashboard/AdminExecutiveList/Body/UserListGrid/RemoveCompanyUserCell';
import type { IPartnerFirm } from '@/admin/components/pages/AllFirms';
import type { ICollaborators } from '@/admin/components/pages/CollaboratorsList';
import type {
  ICompany,
  ICompanyReq,
  ISuperAdminDashboardCompany,
} from '@/admin/interfaces';
import { adminAPISlice } from '@/admin/services/index';
import type { IResponseBase, TObjectId } from '@/interfaces';
import type { ITransformCountResponse } from '@/interfaces/services';

export const companyAPISlice = adminAPISlice.injectEndpoints({
  endpoints: (build) => ({
    getCompanies: build.query<ICompany[], EmptyQueryParameter>({
      query: () => 'api/companies/',
      transformResponse: (response: ITransformCompaniesResponse) => {
        return response.data;
      },
      // providesTags: (result) =>
      //   // is result available?
      //   result
      //     ? // successful query
      //       [
      //         ...result.map(({ id }) => ({ type: 'Companies' as const, id })),
      //         { type: 'Companies', id: 'LIST' }
      //       ]
      //     : // an error occurred, but we still want to refetch this query when `{ type: 'Companies', id: 'LIST' }` is invalidated
      //       [{ type: 'Companies', id: 'LIST' }]
    }),

    getSuperAdminDashboardCompanies: build.query<
      ISuperAdminDashboardCompany[],
      EmptyQueryParameter
    >({
      query: () => 'api/companies/superAdminDashboardCompanies',
      transformResponse: (response: ITransformSuperAdminCompaniesResponse) => {
        return response.data;
      },
    }),

    getCompanyByUser: build.query<ICompany, string>({
      query: (userId: string) => `api/companies/users/${userId}`,
      transformResponse: (response: ITransformCompanyResponse) => {
        return response.data;
      },
    }),

    addCompanyUser: build.mutation({
      query: ({
        user,
        companyId,
      }: {
        user: {
          name: string;
          email: string;
          password: string;
          role: string;
          mobile: string;
          jobTitle: string;
        };
        companyId: string;
      }) => ({
        url: 'api/companies/users',
        method: 'POST',
        body: { user, companyId },
      }),
    }),

    deleteUser: build.mutation<IResponseBase, IDeleteUserFromCompanyRequest>({
      query: ({ userId, companyId }) => ({
        url: `api/companies/${companyId}/users/${userId}`,
        method: 'DELETE',
      }),
    }),

    deletePreSaleFromCompany: build.mutation<
      IResponseBase,
      IDeleteUserFromCompanyRequest
    >({
      query: ({ userId, companyId }) => ({
        url: `api/companies/${companyId}/preSales/${userId}`,
        method: 'DELETE',
      }),
    }),
    deleteGreFromCompany: build.mutation<
      IResponseBase,
      IDeleteUserFromCompanyRequest
    >({
      query: ({ userId, companyId }) => ({
        url: `api/companies/${companyId}/gre/${userId}`,
        method: 'DELETE',
      }),
    }),
    deleteFinanceExecutiveFromCompany: build.mutation<
      IResponseBase,
      IDeleteUserFromCompanyRequest
    >({
      query: ({ userId, companyId }) => ({
        url: `api/companies/${companyId}/financeExecutives/${userId}`,
        method: 'DELETE',
      }),
    }),

    addCompany: build.mutation({
      query: (company: ICompanyReq) => ({
        url: 'api/companies/',
        method: 'POST',
        body: company,
      }),
      // invalidatesTags: [{ type: 'Companies', id: 'LIST' }]
    }),

    updateCompany: build.mutation({
      query: ({ id: companyId, ...company }: ICompany) => ({
        url: `api/companies/${companyId}`,
        method: 'PUT',
        body: company,
      }),
      // invalidatesTags: [{ type: 'Companies', id: 'LIST' }]
    }),

    getCompanyCpFirm: build.query<ICompany[], EmptyQueryParameter>({
      query: () => 'api/companies/cp-firm',
      transformResponse: (response: ITransformCompaniesResponse) => {
        return response.data;
      },
    }),

    getCompanyDetailsByReraNumber: build.mutation({
      query: (reraNumber: string) => ({
        url: `api/companies/rera/${reraNumber}`,
        method: 'GET',
      }),
    }),

    getPartnerFirms: build.query<
      IPartnerFirm[],
      { organisationId: TObjectId | undefined }
    >({
      query: ({ organisationId }: { organisationId: TObjectId }) =>
        `api/companies/cp-firm/${organisationId}`,
      transformResponse: (response: ITransformPartnerFirmsResponse) => {
        return response.data;
      },
    }),

    getProjectPartnerFirms: build.mutation({
      query: ({
        projectId,
        pageNumber,
        pageCount,
        searchField = '',
        searchName = '',
        startDate = '',
        endDate = '',
      }: {
        projectId: TObjectId;
        pageNumber: number;
        pageCount: number;
        searchField: string;
        searchName?: string;
        startDate?: string;
        endDate?: string;
      }) => ({
        url: `api/projects/${projectId}/cp-firm?searchField=${searchField}&searchName=${searchName}&pageCount=${pageCount}&pageNumber=${pageNumber}&startDate=${startDate}&endDate=${endDate}`,
        method: 'GET',
      }),
    }),
    getProjectPartnerFirmsCount: build.query<
      number,
      {
        projectId: TObjectId;
        searchField: string;
      }
    >({
      query: ({
        projectId,
        searchField,
      }: {
        projectId: TObjectId;
        searchField: string;
      }) =>
        `api/projects/${projectId}/cp-firm/count?searchField=${searchField}`,
      transformResponse: (response: ITransformCountResponse) => {
        return response.data;
      },
    }),
    getCollaborators: build.query<
      ICollaborators[],
      { organisationId: TObjectId | undefined }
    >({
      query: ({ organisationId }: { organisationId: TObjectId }) =>
        `api/companies/collaborators/${organisationId}`,
      transformResponse: (response: ITransformCollaboratorsResponse) => {
        return response.data;
      },
    }),
  }),
  overrideExisting: false,
});

interface ITransformCompaniesResponse extends IResponseBase {
  data: ICompany[];
}

interface ITransformCompanyResponse extends IResponseBase {
  data: ICompany;
}

interface ITransformCollaboratorsResponse extends IResponseBase {
  data: ICollaborators[];
}

interface ITransformPartnerFirmsResponse extends IResponseBase {
  data: IPartnerFirm[];
}

interface ITransformSuperAdminCompaniesResponse extends IResponseBase {
  data: ISuperAdminDashboardCompany[];
}

interface EmptyQueryParameter {}

export const {
  useGetCompaniesQuery,
  useGetSuperAdminDashboardCompaniesQuery,
  useGetCompanyByUserQuery,
  useAddCompanyUserMutation,
  useDeleteUserMutation,
  useDeletePreSaleFromCompanyMutation,
  useDeleteGreFromCompanyMutation,
  useDeleteFinanceExecutiveFromCompanyMutation,
  useAddCompanyMutation,
  useUpdateCompanyMutation,

  useGetCompanyCpFirmQuery,
  useGetCompanyDetailsByReraNumberMutation,

  useGetPartnerFirmsQuery,
  useGetProjectPartnerFirmsMutation,
  useGetProjectPartnerFirmsCountQuery,

  useGetCollaboratorsQuery,
} = companyAPISlice;
