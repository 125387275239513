import { addMonths, endOfMonth, setDate } from 'date-fns';

import { MEASURE_UNIT } from '@/booking/constants';
import { ONE_CRORE, ONE_LAKH } from '@/constants/index';

export const isEmpty = (obj: object | undefined) =>
  !obj || (obj && Object.keys(obj).length === 0 && obj.constructor === Object);

export const toIndianRupee = (number = 0) => {
  const roundOffNumber = Math.round(number);
  return parseFloat(roundOffNumber.toString()).toLocaleString('en-IN', {
    maximumFractionDigits: 0,
    style: 'currency',
    currency: 'INR',
  });
};

export const convertMeasureUnitToDisplayText = (unit: string) => {
  switch (unit) {
    case MEASURE_UNIT.SQFT:
      return 'Sq.Ft.';
    case MEASURE_UNIT.SQMT:
      return 'Sq.Mt.';
    default:
      return '';
  }
};

export const ordinalSuffixOf = (number: number) => {
  const j = number % 10,
    k = number % 100;
  if (j === 1 && k !== 11) {
    return number + 'st';
  }
  if (j === 2 && k !== 12) {
    return number + 'nd';
  }
  if (j === 3 && k !== 13) {
    return number + 'rd';
  }
  return number + 'th';
};

export const rankToWordMapping = [
  'Zeroth',
  'First',
  'Second',
  'Third',
  'Fourth',
  'Fifth',
  'Sixth',
  'Seventh',
  'Eighth',
  'Ninth',
  'Tenth',
  'Eleventh',
  'Twelfth',
  'Thirteenth',
  'Fourteenth',
  'Fifteenth',
  'Sixteenth',
  'Seventeenth',
  'Eighteenth',
  'Nineteenth',
];

export const randomId = () =>
  Math.random()
    .toString(36)
    .replace(/[^a-z]+/g, '')
    .substr(0, 5);

export const toTwoDigits = (seconds: number) => {
  return seconds < 10 ? `0${seconds}` : `${seconds}`;
};

// program to convert first letter of a string to uppercase
export const capitalizeFirstLetter = (str: string): string => {
  // converting first letter to uppercase
  const capitalized = str.charAt(0).toUpperCase() + str.slice(1);

  return capitalized;
};

export const capitalizeFirstAndLowercaseRest = (input?: string) => {
  if (!input) return input;
  return input.charAt(0).toUpperCase() + input.slice(1).toLowerCase();
};

export const getPriceInFig = (priceValue: number) => {
  if (priceValue >= ONE_CRORE) {
    return `${(priceValue / ONE_CRORE).toFixed(2)} Cr`;
  } else if (priceValue >= ONE_LAKH) {
    return `${(priceValue / ONE_LAKH).toFixed(2)} L`;
  }

  return priceValue;
};

export const getDateOfNextMonth = (
  date: Date,
  day: number | null = null
): Date => {
  const currentDate = new Date(date); // Replace with your desired date
  const nextMonth = addMonths(currentDate, 1);
  let lastDayOfNextMonth;
  if (day !== null) {
    lastDayOfNextMonth = setDate(nextMonth, 15);
  } else {
    lastDayOfNextMonth = endOfMonth(nextMonth);
  }
  return lastDayOfNextMonth;
};
